<template>
  <div class="d-flex">
    <v-text-field :suffix="suffix" :value="value" :label="label" :prefix="prefix" @input="handleInput" :rules="rules"/>
    <tooltip left>
      <slot name="tooltip"/>
    </tooltip>
  </div>
</template>

<script>
  export default {
    components: {
      Tooltip: () => import('./Tooltip'),
    },
    props: ['value','rules','label', 'prefix', 'suffix'],
    methods: {
      handleInput (value) {
        this.$emit('input', value)
      }
    },
  }
</script>